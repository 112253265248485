<template>
  <b-row align-h="center" v-if="dataLoading">
    <b-spinner
      class="mt-4"
      style="width: 4rem; height: 4rem"
      type="grow"
      variant="primary"
      label="Spinning"
    ></b-spinner>
  </b-row>
  <div v-else>
    <b-modal
      id="modal-password"
      hide-footer
      centered
      title="Password"
      size="sm"
      no-close-on-backdrop
      :hide-header-close="request"
    >
      <div>
        <h5>Enter password to save settings</h5>

        <b-row class="">
          <b-col md="12">
            <b-input-group class="input-group-merge">
              <b-form-input
                ref="pass"
                v-model="password"
                placeholder="Enter password"
                class="form-control-merge"
                :type="passwordFieldType"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col md="12" class="text-right">
            <b-button variant="success" @click="save()" :disabled="request">
              <b-spinner v-if="request" small type="grow" />
              <span v-else> Save </span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-row class="match-height" align-h="center">
      <b-col lg="6" md="9">
        <h4>Webiste Branding</h4>
        <b-card>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Select Logo"
                invalid-feedback="Logo are required"
                required
                ref="logo"
              >
                <div>
                  <b-spinner
                    v-if="logoLoading"
                    type="grow"
                    label="Loading..."
                    variant="primary"
                    class="m-5"
                  />
                  <b-img
                    v-else
                    ref="previewEl"
                    :src="logo"
                    style="border-radius: 10px; object-fit: cover"
                    height="100px"
                    width="100px"
                    @click="$refs.logoInput.click()"
                    alt="logo"
                  />

                  <input
                    type="file"
                    id="logoInput"
                    hidden
                    ref="logoInput"
                    @change="addLogo()"
                    accept="image/*"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Select Login Cover"
                invalid-feedback="Login Cover are required"
                required
                ref="cover"
              >
                <div>
                  <b-spinner
                    v-if="coverLoading"
                    type="grow"
                    label="Loading..."
                    variant="primary"
                    class="m-5"
                  />
                  <b-img
                    v-else
                    ref="previewEl"
                    :src="cover"
                    style="border-radius: 10px; max-height: 200px"
                    @click="$refs.coverInput.click()"
                    fluid
                    alt="cover"
                  />

                  <input
                    type="file"
                    id="coverInput"
                    hidden
                    ref="coverInput"
                    @change="addCover()"
                    accept="image/*"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- <b-col md="12">
              <label class="bv-no-focus-ring col-form-label pt-0">
                Choose color
              </label>
              <div class="d-flex">
                <color-picker v-model="colorSelectedWeb" @input="setWeb" />
                <div
                  class="ml-1"
                  style="width: 55px; height: 55px; border-radius: 8px"
                  :style="{ backgroundColor: colorWeb }"
                ></div>
              </div>
            </b-col> -->
          </b-row>
        </b-card>
      </b-col>
      <b-col lg="6" md="9">
        <h4>App Branding</h4>
        <b-card>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Select Logo SVG"
                invalid-feedback="Logo SVG are required"
                required
                ref="logo"
              >
                <div>
                  <b-spinner
                    v-if="svgLoading"
                    type="grow"
                    label="Loading..."
                    variant="primary"
                    class="m-5"
                  />
                  <b-img
                    v-else
                    ref="previewEl"
                    :src="svg"
                    style="border-radius: 10px; max-height: 150px"
                    fluid
                    @click="$refs.svgInput.click()"
                    alt="svg"
                  />

                  <input
                    type="file"
                    id="svgInput"
                    hidden
                    ref="svgInput"
                    @change="addSvg()"
                    accept=".svg"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Select Loading Screen Logo"
                invalid-feedback="Loading Screen Logo are required"
                required
                ref="splash"
              >
                <div>
                  <b-spinner
                    v-if="splashLoading"
                    type="grow"
                    label="Loading..."
                    variant="primary"
                    class="m-5"
                  />
                  <b-img
                    v-else
                    ref="previewEl"
                    :src="splash"
                    style="border-radius: 10px; max-height: 200px"
                    @click="$refs.splashInput.click()"
                    fluid
                    alt="loading logo"
                  />

                  <input
                    type="file"
                    id="splashInput"
                    hidden
                    ref="splashInput"
                    @change="addSplash()"
                    accept="image/*"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <label class="bv-no-focus-ring col-form-label pt-0">
                Choose color
              </label>
              <div class="d-flex">
                <color-picker v-model="colorSelectedApp" @input="setApp" />
                <div
                  class="ml-1"
                  style="width: 55px; height: 55px; border-radius: 8px"
                  :style="{ backgroundColor: `rgb(${colorApp})` }"
                ></div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="4">
        <b-button variant="success" :disabled="request" @click="openPass()">
          <!--  @click="testing" -->
          <b-spinner v-if="request" small type="grow" />
          <span v-else> Save </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { $themeConfig, $themeColors } from "@themeConfig";

import { mapActions, mapGetters } from "vuex";
import { Chrome } from "vue-color";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormTextarea,
    vSelect,
    flatPickr,
    ColorPicker: Chrome,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      dataLoading: false,
      appLogoImage: $themeConfig.app.appLogoImage,
      coverImage: $themeConfig.app.coverImage,
      myObj: {
        id: 0,
        webTitle: "",
        webLogo: "",
        webColor: "",
        webLoginCover: "",
        appTitle: "",
        appColor: "",
        appLogo: "",
        appSplashCover: "",
        db: this.$store.state.userData.db,
        campusID: this.$store.state.userData.cId,
      },
      passwordFieldType: "password",
      password: "",
      request: false,
      colorWeb: "",
      colorSelectedWeb: "",
      colorApp: "",
      colorSelectedApp: "",
      logoLoading: false,
      logo: this.$store.state.appConfig.appLogoImage,
      coverLoading: false,
      cover: this.$store.state.appConfig.coverImage,
      splashLoading: false,
      splash: "https://cdn.cloudious.net/file-1678189226453-406060527.png",
      svgLoading: false,
      svg: "https://cdn.cloudious.net/file-1678189226453-406060527.png",
    };
  },
  created() {
    this.loadData();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      put: "put",
      delete: "delete",
      postPass: "postPass",
    }),
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    setApp() {
      // console.log(this.colorSelectedApp.rgba);
      let rgba = this.colorSelectedApp.rgba;
      this.colorApp = `${rgba.r},${rgba.g},${rgba.b},${rgba.a}`;
      // this.colorApp = this.colorSelectedApp.hex8;
    },
    setWeb() {
      this.colorWeb = this.colorSelectedWeb.hex8;
    },
    testing() {
      let root = document.documentElement;
      // console.log(getComputedStyle(root));
      // document.documentElement.style.setProperty("$primary", this.colorWeb);
    },
    addSvg() {
      let logo = this.$refs.svgInput.files[0];
      if (logo != "") {
        this.svgLoading = true;
        let formData = new FormData();
        formData.append("file", logo);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.myObj.appLogo = resp;
            this.svg = resp;
            this.svgLoading = false;
          });
      }
    },
    addLogo() {
      let logo = this.$refs.logoInput.files[0];
      if (logo != "") {
        this.logoLoading = true;
        let formData = new FormData();
        formData.append("file", logo);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.logo = resp;
            this.logoLoading = false;
          });
      }
    },
    addCover() {
      let logo = this.$refs.coverInput.files[0];
      if (logo != "") {
        this.coverLoading = true;
        let formData = new FormData();
        formData.append("file", logo);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.cover = resp;
            this.coverLoading = false;
          });
      }
    },
    addSplash() {
      let logo = this.$refs.splashInput.files[0];
      if (logo != "") {
        this.coverLoading = true;
        let formData = new FormData();
        formData.append("file", logo);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.myObj.appSplashCover = resp;
            this.splash = resp;
            this.coverLoading = false;
          });
      }
    },

    async loadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "branding/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      if (res.length > 0) {
        this.myObj = res[0];
        if (this.myObj.appLogo) {
          this.svg = this.myObj.appLogo;
        }
        if (this.myObj.appSplashCover) {
          this.splash = this.myObj.appSplashCover;
        }
        if (!this.myObj.appColor || this.myObj.appColor == "-") {
          this.colorApp = "128,116,229";
          this.colorSelectedApp = {
            r: 128,
            g: 116,
            b: 229,
            a: 1,
          };
        } else {
          this.colorApp = this.myObj.appColor;
          let color = this.myObj.appColor.split(",");
          this.colorSelectedApp = {
            r: color[0],
            g: color[1],
            b: color[2],
            a: color[3] ? color[3] : 1,
          };
        }
        if (!this.myObj.webLoginCover || this.myObj.webLoginCover == "-") {
          this.cover = this.coverImage;
        } else {
          this.cover = this.myObj.webLoginCover;
          this.$store.commit("appConfig/UPDATE_COVERIMAGE", this.cover);
        }
        if (!this.myObj.webLogo || this.myObj.webLogo == "-") {
          this.logo = this.appLogoImage;
        } else {
          this.logo = this.myObj.webLogo;
          this.$store.commit("appConfig/UPDATE_APP_LOGOIMAGE", this.logo);
        }
        // if (!this.myObj.webColor || this.myObj.webColor == "-") {
        //   this.colorWeb = "128,116,229";
        //   this.colorSelectedWeb = {
        //     r: 128,
        //     g: 116,
        //     b: 229,
        //     a: 1,
        //   };
        // } else {
        //   this.colorWeb = this.myObj.webColor;
        //   let color = this.myObj.webColor.split(",");
        //   this.colorSelectedWeb = {
        //     r: color[0],
        //     g: color[1],
        //     b: color[2],
        //     a: color[3] ? color[3] : 1,
        //   };
        // }
      } else {
        this.colorApp = "128,116,229";
        this.colorSelectedApp = {
          r: 128,
          g: 116,
          b: 229,
          a: 1,
        };
        this.colorWeb = "128,116,229";
        this.colorSelectedWeb = {
          r: 128,
          g: 116,
          b: 229,
          a: 1,
        };
        this.cover = this.coverImage;
        this.$store.commit("appConfig/UPDATE_COVERIMAGE", this.cover);
        this.logo = this.appLogoImage;
        this.$store.commit("appConfig/UPDATE_APP_LOGOIMAGE", this.logo);
      }
      this.dataLoading = false;
    },
    openPass() {
      this.$bvModal.show("modal-password");
    },
    async save() {
      if (!this.password) {
        this.$bvToast.toast("Please enter the Password", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.request = true;
        this.myObj.webLogo = this.logo;
        this.myObj.webLoginCover = this.cover;
        this.myObj.appColor = this.colorApp;

        // console.log(this.myObj);

        var res = await this.postPass({
          url:
            this.$store.state.domain +
            "branding/SaveData?db=" +
            this.$store.state.userData.db +
            "&password=" +
            this.password,
          body: this.myObj,
          message: "Data saved successfully",
          context: this,
          token: this.$store.state.userData.token,
        });

        this.request = false;
        if (!res) {
          this.$bvToast.toast("Wrong Password", {
            title: "Error!",
            variant: "danger",
            toaster: "b-toaster-top-center",
          });
        } else {
          this.$bvModal.hide("modal-password");
          this.loadData();
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped></style>
